import React, { useState } from "react";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    // Implement login logic here
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    // Implement logout logic here
    setIsLoggedIn(false);
  };

  return (
    <header className="bg-red-700 text-white p-2 shadow-md mb-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <h1 className="text-2xl font-bold mr-4">Daksh Global</h1>
          <nav className="space-x-4">
            <a href="/" className="hover:text-gray-200">Home</a>
            
            <a href="/" className="hover:text-gray-200">Gallery</a>
            {/* <a href="/" className="hover:text-gray-200">News</a>
            <a href="/" className="hover:text-gray-200">Team</a> */}
            <a href="#contactus" className="hover:text-gray-200">Contact</a>
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          {isLoggedIn ? (
            <button onClick={handleLogout} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Logout
            </button>
          ) : (
            <>
              <button onClick={handleLogin} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Login
              </button>
              <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Sign Up
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;