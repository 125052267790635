import React from "react";
import { Logo } from "../config/ImageConfig";
const ContactUs = () => {
  return (
    <div className="flex flex-col lg:flex-row min-h-[60vh] bg-amber-100 mt-1">
      {/* Left Section: Logo and About Us */}
      <div className="flex flex-col items-center justify-center w-full lg:w-1/4 bg-red-600 text-white p-6">
        {/* Logo */}
        <div className="mb-4">
          <img
            src={Logo} // Replace with your logo
            alt="Logo"
            className="w-24 h-24 object-contain"
          />
        </div>

        {/* About Us */}
        <div className="text-center">
          <h2 className="text-xl font-bold mb-3">About Us</h2>
          <p className="text-sm">
          Our vision is to build a global network for the Prajapati/Kumahar community that fosters pride, innovation, and opportunities for all. At Daskh Global, we unite hearts, empower minds, and celebrate our traditions while shaping a brighter future together. Join us to achieve greatness!
          </p>
        </div>
      </div>

      {/* Center Section: Contact Details */}
      <div className="flex flex-col items-center justify-center w-full lg:w-1/2 bg-amber-200 p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Contact Us</h2>
        <div className="mb-4">
          {/* Contact Details */}
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Our Contact Details</h3>
          <p className="text-sm text-gray-600">Phone: +91-9910070347</p>
          <p className="text-sm text-gray-600">Email: connectdakshglobal@gmail.com</p>
          <p className="text-sm text-gray-600">Address: New Delhi, India</p>
        </div>

        {/* Team Details */}
        <div>
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Our Team</h3>
          <ul className="text-sm text-gray-600">
            <li>Indrajeet Verma</li>
            
          </ul>
        </div>
      </div>

      {/* Right Section: Contact Form */}
      <div className="flex items-center justify-center w-full lg:w-1/4 bg-white p-6">
        <div className="w-full max-w-md">
          <h3 className="text-lg font-bold text-gray-800 mb-3">Get in Touch</h3>
          <form className="space-y-3">
            {/* Name */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                id="name"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Your Name"
              />
            </div>

            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Your Email"
              />
            </div>

            {/* Subject */}
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Subject"
              />
            </div>

            {/* Message */}
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <textarea
                id="message"
                rows="3"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Your Message"
              ></textarea>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="w-full bg-red-700 text-white font-medium py-2 px-4 rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
