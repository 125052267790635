import React, { useState } from "react";

const Dashboard = () => {
  const [posts] = useState([
    {
      id: 1,
      content: "First post!",
      likes: 10,
      comments: ["Nice post!", "Great!"],
    },
    {
      id: 2,
      content: "Another update!",
      likes: 5,
      comments: ["Interesting!", "Keep sharing!"],
    },
  ]);
 //setPosts(state=>state);
  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      <header className="bg-indigo-600 text-white p-4 text-center font-bold text-lg">
        Dashboard
      </header>

      {/* Main Content */}
      <div className="flex flex-1 overflow-hidden">
        {/* Left Sidebar */}
        <aside className="w-1/4 bg-gray-100 p-4 overflow-y-auto">
          <h2 className="font-bold text-lg mb-4">Recently Registered</h2>
          <ul>
            <li className="mb-2">User 1</li>
            <li className="mb-2">User 2</li>
            <li className="mb-2">User 3</li>
          </ul>
        </aside>

        {/* Center Content */}
        <main className="flex-1 p-4 overflow-y-auto">
          {/* Carousel */}
          <div className="mb-6">
            <h2 className="font-bold text-lg mb-4">Image Carousel</h2>
            <div className="h-48 bg-gray-200 flex items-center justify-center">
              <p>Carousel Placeholder</p>
            </div>
          </div>

          {/* News Feed */}
          <div>
            <h2 className="font-bold text-lg mb-4">News Feed</h2>
            {posts.map((post) => (
              <div
                key={post.id}
                className="bg-white p-4 mb-4 shadow rounded-md"
              >
                <p>{post.content}</p>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <button className="mr-4 hover:text-indigo-600">
                    ❤️ {post.likes} Likes
                  </button>
                  <span>{post.comments.length} Comments</span>
                </div>
              </div>
            ))}
          </div>
        </main>

        {/* Right Sidebar */}
        <aside className="w-1/4 bg-gray-100 p-4 overflow-y-auto">
          {/* Upcoming Events */}
          <section className="mb-6">
            <h2 className="font-bold text-lg mb-4">Upcoming Events</h2>
            <ul>
              <li className="mb-2">Event 1 - 2nd Dec</li>
              <li className="mb-2">Event 2 - 5th Dec</li>
              <li className="mb-2">Event 3 - 10th Dec</li>
            </ul>
          </section>

          {/* Matrimony Profiles */}
          <section>
            <h2 className="font-bold text-lg mb-4">Matrimony Profiles</h2>
            <ul>
              <li className="mb-2">Profile 1</li>
              <li className="mb-2">Profile 2</li>
              <li className="mb-2">Profile 3</li>
            </ul>
          </section>
        </aside>
      </div>
    </div>
  );
};

export default Dashboard;
