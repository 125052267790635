import React from "react";
import Navbar from "./Navbar";
import ImageCarousel from "./ImageComponents/ImageCorousel";
import {ImageUrls} from "../config/ImageConfig";
import Contactus from "../components/contactus";
import ComingSoon from "./ComingSoon";
const Home = () => {
    return(
        <div className="App bg-amber-100">
            <Navbar />

            <ImageCarousel images={ImageUrls} interval={3000}/>
            <ComingSoon></ComingSoon>
            <div id="contactus">
            <Contactus></Contactus>
            </div>
            
    </div>
    );
};

export default Home;