import React from "react";

const ComingSoon = ()=>{
    return (
        <div className="flex flex-col lg:flex-row min-h-[80vh] bg-amber-100 mt-1">
            <div className="flex flex-col items-center justify-center w-full text-Black p-6">
            <h1 class="text-4xl font-bold">Comming Soon....</h1>
                       
            </div>
        </div>
    );

};

export default ComingSoon;
